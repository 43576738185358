import { UPDATE_TREE } from '../actions/explorerActions'

const initialState = {
}

export default function explorer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_TREE:
            return {...state, tree: action.payload.tree};
        default:
            return state
    }
}
