import React, { Component } from "react";
import Typography from '@mui/material/Typography';
import { createStyle } from "../styling";
import { Container, Row, Col } from 'reactstrap';

import axios from 'axios';
import { hostname } from '../host';
import { connect } from 'react-redux';
import { handleAlertOpen, handleProgressOpen, handleProgressClose } from '../redux/actions/alertActions';
import { handleSetBreadcrumbName } from '../redux/actions/breadcrumbActions';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NavLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';

class Monday extends Component {
	constructor(props) {
		super(props);
		let self = this;

		this.handleSetBreadcrumbName = this.props.handleSetBreadcrumbName.bind(this);
		this.handleAlertOpen = this.props.handleAlertOpen.bind(this);
		this.handleProgressOpen = this.props.handleProgressOpen.bind(this);
        this.handleProgressClose = this.props.handleProgressClose.bind(this);

		this.state = {
			gettingStarted: false,
			alreadyStarted: false
		};

		self.handleProgressOpen();
		axios.get(hostname() + 'rest/projects?id=' + props.match.params.id)
			// handle success
			.then(function (response) {
				self.setState(state => ({
					'gettingStarted': !response.data.hasTaskBoards,
					'alreadyStarted': response.data.hasTaskBoards,
					'project': response.data,
					'taskBoards': response.data.taskBoards
				}));
				self.handleSetBreadcrumbName({key: '/' + response.data.id, value: response.data.projectName});
			})

			// handle error
			.catch(function (error) {
				self.setState(state => ({
					'project': undefined
				}));
				self.handleSetBreadcrumbName({key: '/' + props.match.params.id, value: 'Dashboard'});

				console.log(error);
				self.handleProgressClose();
				self.handleAlertOpen({ title: 'Error', severity: 'error', message: 'An error occurred and the server could not be reached.' });
			})

			// always executed
			.then(function () {
				self.handleProgressClose();
			});
	}

	handleClick(event) {
		let currentTarget = event.currentTarget; // because otherwise becomes null async
		this.setState(state => ({
			'anchorEl': currentTarget,
			'open': true
		}));
	}

	handleClose() {
		this.setState(state => ({
			'anchorEl': undefined,
			'open': false
		}));
	}

	render() {
		let self = this;
		const { classes } = this.props;

 		return (
			<Container style={{ marginBottom: "100px" }}>
				<Row>
					<Col xs="12" sm="12">
					{	this.state.alreadyStarted &&
						<div>
							<Typography variant="h3" style={createStyle('sectionHeader', { 'display': 'inline-block' })}>
							Task Boards
							</Typography>
							<Button style={{ padding: "6px", minWidth: "0", verticalAlign: "top", color: "#585858", borderRadius: "24px", marginTop: "12px" }} aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick.bind(this)}>
								<MoreVertIcon fontSize="small"/>
							</Button>
							<Menu
								id="simple-menu"
								anchorEl={this.state.anchorEl}
								getContentAnchorEl={null}
								anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
								transformOrigin={{ vertical: "top", horizontal: "left" }}
								keepMounted
								open={this.state.open}
								onClose={this.handleClose.bind(this)}
							>
								<MenuItem onClick={self.handleClose.bind(self)}>
									<NavLink to="create-board">
										<Typography className="title" style={createStyle('navTypography')}>Create a task board</Typography>
									</NavLink>
								</MenuItem>
							</Menu>
						</div>
					}

					{	this.state.gettingStarted &&
						<Typography style={createStyle('siteTypography')}>
						There are currently no task boards for this project.
						</Typography>
					}
					</Col>
				</Row>

				<Row>
					{	this.state.gettingStarted &&
					<Col xs="12" sm="5">
						<Button style={{ marginTop: "8px" }} variant="outlined" color="primary" component={NavLink} to="create-board">Create a task board</Button>
					</Col>
					}
				</Row>
				<Row style={{ display: "flex" }}>
					<Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>

				{	this.state.alreadyStarted &&
					this.state.taskBoards.map(function(taskBoard) {
						return (
							<Grid item xs="12" sm="6" md="4" lg="3">
							<Card>
								<CardContent>
									<Typography noWrap variant="h5" component="h2">
										{taskBoard.boardName}
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small" variant="contained" component={NavLink} to={ "monday/" + taskBoard.boardId}>Open</Button>
								</CardActions>
							</Card>
							</Grid>
						);
					})
				}
					</Grid>
				</Row>
			</Container>
		);
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
		handleSetBreadcrumbName: (breadcrumb) => {
            dispatch(handleSetBreadcrumbName(breadcrumb));
		},
		handleAlertOpen: (alert) => {
            dispatch(handleAlertOpen(alert));
		},
		handleProgressOpen: () => {
            dispatch(handleProgressOpen());
		},
        handleProgressClose: () => {
            dispatch(handleProgressClose());
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
	}
}

const styles = theme => ({
	root: {
		display: 'flex'
	},
	content: {
		paddingTop: 0,
		flexGrow: 1
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Monday));
