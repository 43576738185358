// Action Types

export const OPEN_DOCUMENT = 'OPEN_DOCUMENT'
export const CLOSE_DOCUMENT = 'CLOSE_DOCUMENT'
export const LAYOUT_CHANGE = 'LAYOUT_CHANGE'

// Other Constants


// Action creators

export function handleOpenDocument(document) {
    return { type: OPEN_DOCUMENT, payload: { document: document } }
}

export function handleCloseDocument(document) {
    return { type: CLOSE_DOCUMENT, payload: { document: document } }
}

export function handleLayoutChange(layout) {
    return { type: LAYOUT_CHANGE, payload: { layout: layout } }
}
