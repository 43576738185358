import React, { Component } from 'react';
import '../App.css';

import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';

import { renderTextField } from '../forms';
import { Field, reduxForm } from 'redux-form';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { handleAlertOpen, handleProgressOpen, handleProgressClose } from '../redux/actions/alertActions';

import axios from 'axios';
import { hostname } from '../host';

import { Link  } from "react-router-dom";


class DocumentWidget extends Component {
	constructor(props) {
		super(props);
		let self = this;

        this.handleAlertOpen = this.props.handleAlertOpen.bind(this);
        this.handleProgressOpen = this.props.handleProgressOpen.bind(this);
        this.handleProgressClose = this.props.handleProgressClose.bind(this);

		this.state = {
		};

		const html = '<p>Hey this <strong>editor</strong> rocks 😀</p>';
		this.setEditorContent(html);

		axios.get(hostname() + 'rest/documents?id=' + props.nodeId)
			// handle success
			.then(function (response) {
				const html = response.data.content;
				self.setEditorContent(html);
			})

			// handle error
			.catch(function (error) {
				self.setState(state => ({
				}));

				console.log(error);
				//self.handleProgressClose();
				//self.handleAlertOpen({ title: 'Error', severity: 'error', message: 'An error occurred and the server could not be reached.' });
			})

			// always executed
			.then(function () {
				//self.handleProgressClose();
			});
	}

	setEditorContent(html) {
		const contentBlock = htmlToDraft(html);

		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			this.setState(state => ({
				editorState
			}));
		}
	}

	onEditorStateChange(editorState) {
		this.setState(state => ({
			editorState
		}));
	}

	onClick() {
		console.log("clicked");
	}

    updateDocument(data) {
		let self = this;

        let content = draftToHtml(convertToRaw(
            this.state.editorState.getCurrentContent()
        ));

		let formData = new FormData();
		formData.append('content', content);

		// Submit the form
		self.handleProgressOpen();
		axios.put(
			'/rest/documents?id=' + data.id,
			formData,
			{
				headers: { 'Content-Type': 'multipart/form-data' }
			}
		)

		// handle success
		.then(function (response) {
			self.handleAlertOpen({ title: 'Success', severity: 'success', message: 'Your document was successfully saved.' });
		})

		// handle error
		.catch(function (error) {
			self.handleAlertOpen({ title: 'Error', severity: 'error', message: 'An error occurred and your submission could not be delivered.' });
		})

		// always executed
		.then(function () {
			self.handleProgressClose();
		});
	}

    render() {
		const { classes } = this.props;
        return (

			/*<div>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque enim erat, pellentesque non ex pellentesque, ornare porttitor magna. Donec vel mattis ante. Quisque eget dictum velit. Pellentesque sed justo ut nibh sagittis venenatis. Nullam posuere luctus tellus, non dapibus sapien hendrerit sit amet. Morbi malesuada pulvinar accumsan. Mauris tellus elit, molestie a tempus in, facilisis vel neque. Proin venenatis augue aliquam metus pretium lobortis. Sed rutrum consequat pretium. Nunc interdum purus id mi vehicula, sit amet eleifend elit finibus. Aliquam neque urna, aliquam at euismod eu, luctus at odio. Mauris vel turpis bibendum tellus ultricies semper ac eget nisi. Donec fringilla porttitor tellus vel malesuada. Morbi lobortis lorem nec fermentum luctus. Nam eget velit blandit, maximus urna vel, gravida libero. Sed eu ornare diam.

			Proin non leo malesuada, hendrerit tellus et, tempus tortor. Aenean vitae egestas metus, eget scelerisque orci. Aliquam consequat bibendum sem ac vulputate. Suspendisse imperdiet ante et lorem ultricies hendrerit. Pellentesque quis cursus purus. Fusce eu lobortis metus. Donec rhoncus mauris tellus, et fringilla nisi maximus nec. Nulla mollis sed lectus sed ultrices.

			Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus varius elit eu massa egestas tristique. Maecenas vel augue ante. Maecenas eu semper lacus. Pellentesque placerat dapibus neque, a venenatis tortor. Vestibulum placerat at velit a eleifend. Sed interdum felis ac nibh vulputate viverra.

			Etiam euismod gravida sem, eget laoreet metus efficitur id. Suspendisse ut nulla leo. Fusce dui nisi, dictum vitae pulvinar fringilla, iaculis eu sem. Aliquam at massa lacus. Fusce metus justo, malesuada sed lorem ac, consectetur mollis massa. Etiam sed odio id justo maximus dignissim non congue felis. In risus massa, accumsan in semper auctor, pretium vel ante. Aliquam erat volutpat. Vivamus sodales nec libero a pulvinar. Phasellus tempus accumsan dui at aliquam. Vestibulum nec orci id lorem mattis pretium ut quis turpis.

			Nulla hendrerit at sem mollis ullamcorper. Duis eget venenatis felis. Vestibulum quis accumsan lorem. Morbi sodales lacus sapien, eget tempor lorem sagittis ac. Maecenas nunc metus, convallis a tempus sit amet, laoreet nec purus. Nunc et arcu quam. Pellentesque posuere tellus nibh, at pretium orci convallis in. Quisque imperdiet malesuada gravida. Vivamus tincidunt metus dolor, sit amet dapibus lectus blandit sed. Sed lobortis eleifend dolor, sit amet malesuada erat bibendum non. Maecenas dignissim tortor sed dignissim vehicula.

			Sed lacinia blandit mauris, quis suscipit est fermentum vitae. Nam commodo ipsum sed risus pretium, vitae dictum enim fringilla. Aenean tincidunt ipsum mauris, nec vehicula magna facilisis a. Integer semper velit id lectus dictum mollis. Donec vitae venenatis nisl, eget aliquet sem. Vivamus posuere urna at purus aliquam, id varius massa commodo. Maecenas odio leo, viverra nec enim quis, varius mollis dui. Mauris a volutpat lacus. In dignissim a nulla ac venenatis. Fusce finibus eu ex eget euismod.

			Sed in rutrum elit. Etiam ut ullamcorper tellus, a tempor eros. Mauris feugiat risus nisi, id aliquet urna tincidunt eleifend. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Fusce et velit tellus. Vivamus at dolor tortor. Nullam a fermentum felis. Mauris quis dui rhoncus, tincidunt libero egestas, vehicula leo. Duis rhoncus laoreet eros in eleifend. Pellentesque viverra ultrices dapibus. Nulla eget nisi nec ligula elementum tempus eu non neque. Cras maximus urna id lorem iaculis ultricies. Pellentesque tempus porta ipsum ac auctor. Sed suscipit leo at justo luctus, non vehicula nulla convallis. Vestibulum vehicula est in sodales suscipit. Vestibulum facilisis gravida augue, a semper justo fringilla vitae.

			Nulla a leo vel metus tincidunt posuere. Aenean laoreet venenatis commodo. Integer ac orci ac justo varius posuere. Etiam erat arcu, ultrices vulputate sapien sed, ultrices gravida diam. Vivamus ac maximus dolor, in tempor felis. Nullam placerat, elit id tincidunt pharetra, elit elit ullamcorper elit, sed condimentum est ex quis ligula. Mauris a ex velit.

			Morbi ac tortor tortor. Aenean dapibus tellus eu vulputate accumsan. Aliquam sit amet orci pulvinar, congue libero ac, lacinia ante. Etiam sed efficitur lacus, ut dignissim leo. Etiam malesuada maximus tempor. Sed ligula magna, pulvinar eu leo sed, sodales cursus lectus. Vestibulum volutpat dolor a mollis tempor. Suspendisse dolor ligula, blandit eu dignissim vel, cursus ac metus. Cras consectetur enim a magna dapibus, ac mattis sem ullamcorper. Vivamus vel malesuada neque. Aliquam a placerat dui, quis rutrum nibh. Proin varius mattis massa, at varius libero sollicitudin sit amet.

			Vivamus condimentum at metus a commodo. Nulla tempus orci non placerat accumsan. Quisque quis malesuada elit. Fusce pretium laoreet ligula, sed condimentum turpis cursus a. Sed a mi eu urna placerat rhoncus. Morbi molestie egestas neque id sodales. Vivamus facilisis sem nunc, ac cursus tellus auctor porttitor. Aliquam tristique, metus ac cursus faucibus, risus ligula porta sem, eget gravida lorem nisi ut nulla. Vestibulum semper tellus sed mollis volutpat.

			Nulla sit amet nibh sodales, sagittis ipsum a, ornare ante. Praesent nec luctus justo. Mauris id tincidunt quam, eget dignissim urna. Nam porttitor eros dolor, sit amet venenatis lacus fringilla eu. Mauris pretium accumsan tortor, sit amet blandit metus. Nullam non porta arcu, sit amet congue ipsum. Aliquam erat volutpat. Morbi eu sodales enim, eget consectetur quam. Integer blandit interdum ipsum ut cursus. Nunc volutpat ex in lobortis maximus. Praesent id metus malesuada, semper enim nec, tempor nisi. Ut eleifend est nisl, id tincidunt erat mattis ut. Nunc eros nunc, suscipit a tortor vitae, porta ultricies tellus.

			Curabitur interdum hendrerit sem, a posuere turpis elementum imperdiet. Pellentesque id ultrices lorem. Phasellus odio diam, feugiat quis elementum id, imperdiet pretium neque. Proin ultrices id eros sit amet ullamcorper. Ut vitae tincidunt diam. Morbi et nisl molestie, dignissim eros id, tempor tellus. Fusce tristique aliquam ligula non finibus. Suspendisse scelerisque arcu eu laoreet volutpat.

			Phasellus et luctus ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin suscipit sodales libero quis fermentum. Donec ullamcorper nisl lectus, eu ultrices augue varius non. Proin et sapien aliquet, ornare massa ut, semper tortor. Mauris finibus dictum laoreet. Mauris in augue tempor, iaculis enim lobortis, consectetur odio. Cras rutrum, enim aliquet laoreet ornare, erat nulla hendrerit magna, nec tempor elit tellus id arcu.

			Fusce eu ligula sed neque suscipit posuere ut nec magna. Fusce nec tincidunt libero, ut auctor mauris. In odio mauris, efficitur in euismod ut, ullamcorper eu urna. In nec condimentum neque. Nulla volutpat blandit lacinia. Nam ut lacus finibus, tempus erat at, accumsan sapien. Etiam pharetra lobortis mauris nec efficitur. Phasellus vel suscipit tellus.

			Nulla convallis ligula eu tempus efficitur. Cras laoreet sagittis leo, ac efficitur ipsum molestie sit amet. Maecenas ut odio finibus, consequat lorem nec, blandit nisi. Etiam risus arcu, ornare eget ante in, accumsan pellentesque purus. Sed neque ex, auctor eget mollis a, porttitor id lacus. Integer ante ante, semper at neque nec, aliquam facilisis elit. Sed iaculis ex a lectus consectetur, id imperdiet sem porttitor. Sed sollicitudin nulla augue, ut mollis arcu placerat sed. Vivamus id diam viverra ligula eleifend aliquet.

			Praesent imperdiet nibh risus, eget feugiat mauris egestas eu. Suspendisse aliquet dolor eu nunc finibus, sit amet rutrum ante blandit. Pellentesque euismod iaculis purus in tincidunt. Praesent et quam id quam feugiat feugiat in at est. Praesent malesuada sapien ac tristique ultricies. Nullam eget justo a massa mollis vehicula. Aliquam erat volutpat.

			Morbi et convallis augue, ac sollicitudin lacus. Morbi tempor ultrices vestibulum. Integer eget justo lacinia, imperdiet tortor et, iaculis mauris. Curabitur at semper velit. Cras gravida, neque a viverra mollis, enim mi interdum risus, sit amet placerat enim lectus ac felis. Proin quam justo, dapibus vitae urna non, ultrices interdum quam. Nam sed tempor dolor. Nullam et porttitor purus, id suscipit augue. Maecenas iaculis augue purus. Nulla ut justo finibus, malesuada purus vel, commodo nisi. Ut ligula tortor, ullamcorper vel vestibulum sed, viverra eget leo.

			Phasellus fermentum massa ac nisl dignissim viverra. Sed fermentum sed eros a pharetra. Maecenas volutpat lorem ipsum, efficitur posuere enim gravida et. Curabitur dapibus eros in elit bibendum, sed congue elit feugiat. Fusce molestie mi eget augue sagittis fermentum. Duis et consequat arcu. Maecenas eros purus, pharetra nec odio nec, facilisis suscipit diam. Aliquam vel fringilla lorem. Phasellus egestas justo sagittis risus ornare, sit amet condimentum elit egestas. Fusce luctus auctor mauris, vitae convallis dolor viverra ac.

			Nunc vel egestas risus, vel cursus ex. Phasellus hendrerit diam a est malesuada facilisis. Quisque venenatis rhoncus urna a scelerisque. Suspendisse ut nulla non justo congue pretium id in massa. Nullam sed malesuada ligula. Praesent posuere condimentum hendrerit. Aenean eu finibus lectus. Vivamus sollicitudin sit amet sem varius luctus.

			Donec dignissim et augue ut dictum. Nullam blandit lorem malesuada tellus porta accumsan. Nunc sit amet vehicula nulla. Donec porta, turpis vel faucibus feugiat, sapien neque commodo lectus, nec viverra est sem in ligula. Proin convallis ante lobortis odio fringilla, at euismod lacus tempus. Sed congue eros erat, id rhoncus dolor sagittis ut. Vestibulum in libero at ipsum ullamcorper porta sit amet quis nisl. Donec id elementum felis, ut auctor felis.

			Phasellus ligula dui, ullamcorper sit amet mollis ac, blandit vitae eros. Duis in lectus nisi. Donec vitae vehicula ante, at gravida risus. Proin dapibus hendrerit ex, sed laoreet tellus porttitor sed. Phasellus quis sodales nunc. Praesent laoreet felis vel orci euismod tempus. Etiam commodo, est ac sagittis consequat, neque mi eleifend erat, sed interdum orci risus a ligula. Nunc maximus bibendum urna, nec efficitur ligula eleifend et. Fusce vitae quam vitae ante blandit ultrices sed viverra augue.

			Cras pulvinar felis non enim lacinia, sed facilisis erat dapibus. Fusce neque eros, varius quis tempor eget, pretium elementum justo. Ut lectus turpis, viverra nec justo et, congue sollicitudin felis. Duis tristique arcu in ornare viverra. Curabitur et ipsum quis leo feugiat lacinia. Nunc lobortis nisi at sem bibendum rhoncus. Pellentesque molestie ipsum id ligula faucibus aliquam. Curabitur ornare eros eget nibh pulvinar, id euismod libero gravida. Sed nibh lacus, porttitor ac maximus eleifend, placerat in tortor. Etiam condimentum neque nec malesuada lobortis. Morbi mauris augue, molestie a mollis id, elementum sit amet purus. Mauris convallis, sem eget finibus auctor, libero orci dapibus sem, eleifend malesuada ipsum sapien in nunc.

			Pellentesque volutpat magna vel ante lobortis fringilla. Vestibulum mi tortor, varius ac quam eu, faucibus lacinia urna. Nam a viverra odio. Etiam nunc justo, congue a scelerisque ac, tincidunt a quam. Duis scelerisque arcu rutrum urna tincidunt, in semper lectus rhoncus. Mauris sollicitudin nisi nec magna consectetur fermentum. In hac habitasse platea dictumst. Nulla mattis tempus porta. Curabitur volutpat ullamcorper eleifend. Phasellus venenatis elementum maximus. Aenean eget suscipit nunc.

			Nulla sed malesuada odio. Aenean sagittis mollis lacus sed tristique. Duis sit amet dui neque. Integer scelerisque viverra porttitor. Donec vestibulum justo nec cursus luctus. Curabitur non blandit risus. Integer eu velit placerat, consectetur magna sit amet, dignissim lacus. Nulla lacinia, sem vitae lobortis fringilla, lorem dui lobortis purus, et porttitor lectus velit id nibh. Fusce sed orci metus. Duis hendrerit interdum enim, dignissim malesuada mauris vehicula et. Aenean cursus mattis ex accumsan blandit. Proin id urna tempus, tincidunt tellus vitae, tincidunt ligula. In at purus maximus, scelerisque odio non, blandit nulla. Sed elementum in nunc sed fringilla.

			Phasellus id dignissim justo, vel ultrices nulla. Donec aliquet, eros convallis gravida tincidunt, ipsum lectus suscipit dolor, vel euismod ante diam a orci. Donec ac diam non sem malesuada pharetra quis vel sem. Vestibulum sit amet dolor feugiat, ornare erat non, dapibus elit. In eleifend turpis sed sapien elementum ullamcorper. Curabitur lobortis pulvinar auctor. Suspendisse potenti. Vestibulum id ante nec tellus mattis vulputate in in urna.



			</div>*/

			/*<div>
			hello world
			</div>*/


            <div className="dashboard-item-content" style={{ padding: '0 8px 0 8px', height: '89%', overflow: 'auto' }}>
            <div>
                <Button disabled={this.props.static} style={{ color: '#D7DBDD' }} size="medium" classes={{ root: classes.customizeToolbarControl }} onClick={this.updateDocument.bind(this, { id: this.props.nodeId, content: this.props.content })}>
                    <SaveIcon fontSize="medium" color="inherit"/>
                </Button>
            </div>
            <Editor
				editorState={this.state.editorState}
				onEditorStateChange={this.onEditorStateChange.bind(this)}
				wrapperClassName={classes.customWrapper}
				editorClassName={classes.customEditor}
				toolbarClassName="editor-toolbar"
			/>
            </div>
        )
	}
}

// Decorate the form component
DocumentWidget = reduxForm({
	form: 'DocumentWidget' // a unique name for this form
})(DocumentWidget);

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleAlertOpen: (alert) => {
            dispatch(handleAlertOpen(alert));
		},
        handleProgressOpen: () => {
            dispatch(handleProgressOpen());
		},
        handleProgressClose: () => {
            dispatch(handleProgressClose());
		}
    }
};

const mapStateToProps = (state, ownProps) => {
	return {
	}
};

const styles = theme => ({
	customWrapper: {
		overflow: 'hidden'
	},
	customEditor: {
		overflow: 'auto'
	}
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentWidget));
