import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { handleAlertOpen, handleProgressOpen, handleProgressClose } from '../redux/actions/alertActions';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Container, Row, Col } from 'reactstrap';
import { renderTextField } from '../forms';
import { Field, reduxForm } from 'redux-form';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import Typography from '@mui/material/Typography';

import { createStyle } from "../styling";

class CreateProjectForm extends Component {
	constructor(props) {
		super(props);

        this.handleAlertOpen = this.props.handleAlertOpen.bind(this);
        this.handleProgressOpen = this.props.handleProgressOpen.bind(this);
        this.handleProgressClose = this.props.handleProgressClose.bind(this);

		this.state = {
            projectName: '',
            privacyInput: '',

            projectNameDirty: false,
            privacyInputDirty: false
		};
	}

	resetForm() {
        this.setState({
            projectName: '',
            privacyInput: '',

            projectNameDirty: false,
            privacyInputDirty: false
        });

        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setState({
            itemvalues: [{}]
        });
    }

	handleBlur(event) {
        this.setState({
            [event.target.id + 'InputDirty']: true
        });
        this[event.target.id + 'Validation'](event);
    }

	handleInput(event) {
        this.setState({
            [event.target.id + 'InputDirty']: true
        });
        this[event.target.id + 'Validation'](event);
    }


	nonBlankValidation(event) {
        if (event.target.value.length === 0) {
            this.setState({
                [event.target.id + 'Input']: '',
                [event.target.id + 'InputError']: true
            });
        }
        else {
            this.setState({
                [event.target.id + 'Input']: event.target.value,
                [event.target.id + 'InputError']: false
            });
        }
    }

	projectNameValidation(event) {
        this.nonBlankValidation(event);
    }

	privacyValidation(event) {
        let id = 'privacy';

        if (event.target.value.length === 0) {
            this.setState({
                [id + 'InputError']: true
            });
        }
        else {
            this.setState({
                [id + 'Input']: event.target.value,
                [id + 'InputError']: false
            });
        }
    }

	isValid() {
        return (this.state.projectName.length > 0 &&
            this.state.privacyInput.length > 0
        )
    }

	handleSubmit(data) {
		let self = this;

		let formData = new FormData();
		formData.append('projectName', data.projectName);
		formData.append('privacy', self.state.privacyInput);

		// Submit the form
		self.handleProgressOpen();
		axios.post(
			'/rest/projects',
			formData,
			{
				headers: { 'Content-Type': 'multipart/form-data' }
			}
		)

		// handle success
		.then(function (response) {
			// Reset state after successfully sending the form
			self.resetForm();

			self.handleAlertOpen({ title: 'Success', severity: 'success', message: 'Your project was successfully created.' });
		})

		// handle error
		.catch(function (error) {
			self.handleAlertOpen({ title: 'Error', severity: 'error', message: 'An error occurred and your submission could not be delivered.' });
		})

		// always executed
		.then(function () {
			self.handleProgressClose();
		});
	}

	render() {
		return (
			<form onSubmit={this.props.handleSubmit(this.handleSubmit.bind(this))}>
				<div>
					<Typography variant="h3" style={createStyle('sectionHeader')}>Create a project</Typography>
					<div className="font-styled font-fine login-form-row">
						<Typography style={createStyle('siteTypography')}>
						To create a new project, give it a name and choose from the additional settings below.
						</Typography>
					</div>
					<div className="login-form-row">
						<Field name="projectName" component={renderTextField} label="Project Name" style={{ "width": "80%" }} required/>
					</div>
					<div className="login-form-row">
						<FormControl variant="standard" style={{ "width": "80%" }} required>
							<InputLabel id="demo-simple-select-standard-label">Privacy</InputLabel>
							<Select
								labelId="privacyLabel"
								id="privacy"
								value={this.state.privacyInput}
								onChange={this.privacyValidation.bind(this)}
								displayEmpty
								autoWidth
							>
								<MenuItem value={'PUBLIC'}>Public</MenuItem>
								<MenuItem value={'PRIVATE'}>Private</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="login-form-row">
						<Button type="submit" variant="contained" color="primary">Create Project</Button>
					</div>
				</div>
			</form>
		);
	}
}

// Decorate the form component
CreateProjectForm = reduxForm({
	form: 'createProjectForm' // a unique name for this form
})(CreateProjectForm);

const mapStateToProps = state => {
	return {
		submitSucceeded: state.form?.createProjectForm?.submitSucceeded
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
		handleAlertOpen: (alert) => {
            dispatch(handleAlertOpen(alert));
		},
		handleProgressOpen: () => {
            dispatch(handleProgressOpen());
		},
        handleProgressClose: () => {
            dispatch(handleProgressClose());
		}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectForm);
