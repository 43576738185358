import React, { Component } from 'react';
import clsx from 'clsx';
import '../App.css';

import { Container, Row, Col } from 'reactstrap';
import Typography from '@mui/material/Typography';
import { createStyle } from "../styling";

import GridLayout from 'react-grid-layout';
import RGL, { WidthProvider } from "react-grid-layout";

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Explorer from './Explorer';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SaveIcon from '@mui/icons-material/Save';

import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import { connect } from 'react-redux';
import { handleAlertOpen, handleProgressOpen, handleProgressClose } from '../redux/actions/alertActions';
import { handleSetBreadcrumbName } from '../redux/actions/breadcrumbActions';
import { handleUpdateTree } from '../redux/actions/explorerActions';
import { handleCloseDocument, handleLayoutChange } from '../redux/actions/dashboardActions';

import axios from 'axios';
import { hostname } from '../host';

import { withStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';

const drawerWidth = 240;

class Dashboard extends Component {
	constructor(props) {
		super(props);
		let self = this;

		this.handleLayoutChange = this.props.handleLayoutChange.bind(this);
		this.handleCloseDocument = this.props.handleCloseDocument.bind(this);
		this.updateTree = this.props.updateTree.bind(this);
		this.handleSetBreadcrumbName = this.props.handleSetBreadcrumbName.bind(this);
		this.handleAlertOpen = this.props.handleAlertOpen.bind(this);
		this.handleProgressOpen = this.props.handleProgressOpen.bind(this);
        this.handleProgressClose = this.props.handleProgressClose.bind(this);

		this.state = {
			'explorer': false
		};

		self.handleProgressOpen();
		axios.get(hostname() + 'rest/projects?id=' + props.match.params.id)
			// handle success
			.then(function (response) {
				self.setState(state => ({
					'project': response.data,
					'dashboardTitle': response.data.projectName,
					'hasTaskBoards': response.data.hasTaskBoards
				}));
				self.updateTree(response.data.documents);
				self.handleSetBreadcrumbName({key: '/' + response.data.id, value: response.data.projectName});
			})

			// handle error
			.catch(function (error) {
				self.setState(state => ({
					'project': undefined,
					'dashboardTitle': 'Dashboard'
				}));
				self.handleSetBreadcrumbName({key: '/' + props.match.params.id, value: 'Dashboard'});

				console.log(error);
				self.handleProgressClose();
				self.handleAlertOpen({ title: 'Error', severity: 'error', message: 'An error occurred and the server could not be reached.' });
			})

			// always executed
			.then(function () {
				self.handleProgressClose();
			});
	}

	onLayoutChange(dashboardLayout) {
		let self = this;

		dashboardLayout.forEach(function(el) {
			for (var x = 0; x < self.props.dashboardLayout.length; x++) {
				if (self.props.dashboardLayout[x].i === el.i) {
					self.props.dashboardLayout[x].x = el.x;
					self.props.dashboardLayout[x].y = el.y;
					self.props.dashboardLayout[x].w = el.w;
					self.props.dashboardLayout[x].h = el.h;
				}
			}
		});

		this.props.handleLayoutChange(this.props.dashboardLayout);
	}

	closeDocument(document) {
		this.props.handleCloseDocument(document);
	}

	toggleDrawer(anchor, open) {
		this.setState(state => ({
			[anchor]: open
		}));
    };

	handleClick(event) {
		let currentTarget = event.currentTarget; // because otherwise becomes null async
		this.setState(state => ({
			'anchorEl': currentTarget,
			'open': true
		}));
	};

	handleClose() {
		this.setState(state => ({
			'anchorEl': undefined,
			'open': false
		}));
	};

	render() {
		let self = this;
		const { classes } = this.props;

	    return (
			<div className={classes.root}>
				<Drawer className={classes.drawer} classes={{ paper: classes.paper }} variant="persistent" anchor='left' open={this.state['explorer']} onClose={this.toggleDrawer.bind(this, 'explorer', false)}>
					<Explorer projectId={this.props.match.params.id}/> 
				</Drawer>
				<div className={clsx(classes.content, {[classes.contentShift]: this.state['explorer']})}>
					<Container style={{ paddingLeft: "0" }} fluid>
						<Row>
							<Col>
								<Button style={{ padding: "6px", minWidth: "0", verticalAlign: "top", color: "#585858", borderRadius: "24px" }} onClick={this.toggleDrawer.bind(this, 'explorer', !this.state['explorer'])}>
									{	!this.state['explorer'] &&
										<ChevronRightIcon fontSize="large" color="inherit"/>
									}
									{	this.state['explorer'] &&
										<ChevronLeftIcon fontSize="large" color="inherit"/>
									}
								</Button>
								<Typography variant="h3" style={createStyle('sectionHeader', { 'display': 'inline-block' })}>
									{this.state.dashboardTitle}
								</Typography>

								{
									(this.state.hasTaskBoards) &&

								<Button style={{ padding: "6px", minWidth: "0", verticalAlign: "top", color: "#585858", borderRadius: "24px", marginTop: "12px" }} aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick.bind(this)}>
									<MoreVertIcon fontSize="small"/>
								</Button>
								}

								{
									(this.state.hasTaskBoards) &&

								<Menu
									id="simple-menu"
									anchorEl={this.state.anchorEl}
									getContentAnchorEl={null}
									anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
									transformOrigin={{ vertical: "top", horizontal: "left" }}
									keepMounted
									open={this.state.open}
									onClose={this.handleClose.bind(this)}
								>
									<MenuItem onClick={self.handleClose.bind(self)}>
										<NavLink to={self.state.project.id + "/monday"}>
											<Typography className="title" style={createStyle('navTypography')}>Tasks</Typography>
										</NavLink>
									</MenuItem>
								</Menu>
								}
							</Col>
						</Row>
						<Row>
							<Col>
								<ReactGridLayout className="layout" layout={this.props.dashboardLayout} cols={12} rowHeight={30} width={1200} draggableCancel='.dashboard-item-content' draggableHandle='.dashboard-item-header' onLayoutChange={this.onLayoutChange.bind(this)}>
								{
									(this.props.dashboardLayout && this.props.dashboardLayout.length > 0) &&
									this.props.dashboardLayout.map((widget) => {
										const { content: Widget } = widget;

										if (!widget.content) {
											return (<div style={{ display: 'none'}} key='bugfix'/>);
										}

										/*return (
											<div>Hello world</div>
										);*/

										/*return (
											<div key={widget.i} style={{
												borderLeft: '1px solid #a22f7d',
												borderRight: '1px solid #a22f7d',
												borderBottom: '1px solid #a22f7d',
												overflow: 'hidden',
												backgroundColor: 'white'
											}}>
												<div className="dashboard-item-header">
													<AppBar classes={{ colorPrimary: classes.customizeAppbar }} color="primary" position="sticky" style={{ background: '#a22f7d', color: 'CACFD2' }} elevation={0}>
														<Toolbar className={classes.customizeToolbar}>
															<div style={{ flex: 1, overflow: 'hidden' }}>
																<Typography style={{ fontWeight: 600, whiteSpace: 'nowrap', userSelect: 'none' }}>
																	{widget.title}
																</Typography>
															</div>
															<Button disabled={widget.static} style={{ color: '#D7DBDD' }} size="medium" classes={{ root: classes.customizeToolbarControl }} onClick={this.updateDocument.bind(this, { id: widget.i, content: widget.content })}>
																<SaveIcon fontSize="medium" color="inherit"/>
															</Button>
															<Button disabled={widget.static} style={{ color: '#D7DBDD' }} size="medium" classes={{ root: classes.customizeToolbarControl }} onClick={this.closeDocument.bind(this, { id: widget.i, title: widget.title })}>
																<CloseIcon fontSize="medium" color="inherit"/>
															</Button>
														</Toolbar>
													</AppBar>
												</div>

												<div className="dashboard-item-content" style={{ padding: '0 8px 0 8px', height: '89%', overflow: 'auto' }}>
												<div>Hello world</div>
												</div>
											</div>
										);*/

										/*return (
											<div>{widget.title}</div>
										);*/

										/*return (
											<div key={widget.i}>
												<Widget nodeId={widget.i} title={widget.title} content={widget.content} static={widget.static}/>
											</div>
										);*/

										/*return (
											<Widget key={widget.i} nodeId={widget.i} title={widget.title} content={widget.content} static={widget.static}/>
										);*/

										return (
											<div key={widget.i} style={{
												borderLeft: '1px solid #a22f7d',
												borderRight: '1px solid #a22f7d',
												borderBottom: '1px solid #a22f7d',
												overflow: 'hidden',
												backgroundColor: 'white'
											}}>
												<div className="dashboard-item-header">
													<AppBar classes={{ colorPrimary: classes.customizeAppbar }} color="primary" position="sticky" style={{ background: '#a22f7d', color: 'CACFD2' }} elevation={0}>
														<Toolbar className={classes.customizeToolbar}>
															<div style={{ flex: 1, overflow: 'hidden' }}>
																<Typography style={{ fontWeight: 600, whiteSpace: 'nowrap', userSelect: 'none' }}>
																	{widget.title}
																</Typography>
															</div>
															<Button disabled={widget.static} style={{ color: '#D7DBDD' }} size="medium" classes={{ root: classes.customizeToolbarControl }} onClick={this.closeDocument.bind(this, { id: widget.i, title: widget.title })}>
																<CloseIcon fontSize="medium" color="inherit"/>
															</Button>
														</Toolbar>
													</AppBar>
												</div>

												{
													(widget.content) &&
													<Widget nodeId={widget.i}/>
												}
											</div>
										);

										/*return (
											<div key={widget.i} style={{
												borderLeft: '1px solid #a22f7d',
												borderRight: '1px solid #a22f7d',
												borderBottom: '1px solid #a22f7d',
												overflow: 'hidden',
												backgroundColor: 'white'
											}}>
												<div className="dashboard-item-header">
													<AppBar classes={{ colorPrimary: classes.customizeAppbar }} color="primary" position="sticky" style={{ background: '#a22f7d', color: 'CACFD2' }} elevation={0}>
														<Toolbar className={classes.customizeToolbar}>
															<div style={{ flex: 1, overflow: 'hidden' }}>
																<Typography style={{ fontWeight: 600, whiteSpace: 'nowrap', userSelect: 'none' }}>
																	{widget.title}
																</Typography>
															</div>
															<Button disabled={widget.static} style={{ color: '#D7DBDD' }} size="medium" classes={{ root: classes.customizeToolbarControl }} onClick={this.updateDocument.bind(this, { id: widget.i, content: widget.content })}>
																<SaveIcon fontSize="medium" color="inherit"/>
															</Button>
															<Button disabled={widget.static} style={{ color: '#D7DBDD' }} size="medium" classes={{ root: classes.customizeToolbarControl }} onClick={this.closeDocument.bind(this, { id: widget.i, title: widget.title })}>
																<CloseIcon fontSize="medium" color="inherit"/>
															</Button>
														</Toolbar>
													</AppBar>
												</div>

												<div className="dashboard-item-content" style={{ padding: '0 8px 0 8px', height: '89%', overflow: 'auto' }}>
												{
													(widget.content) &&
													<Widget nodeId={widget.i}/>
												}
												</div>
											</div>
										);*/
									})
								}

								</ReactGridLayout>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
        )
	}
}

const ReactGridLayout = WidthProvider(RGL);

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
		handleLayoutChange: (dashboardLayout, b, c) => {
			dispatch(handleLayoutChange(dashboardLayout));
		},
		handleCloseDocument: (document) => {
			dispatch(handleCloseDocument(document));
		},
		updateTree: (tree) => {
			dispatch(handleUpdateTree(tree));
		},
		handleSetBreadcrumbName: (breadcrumb) => {
            dispatch(handleSetBreadcrumbName(breadcrumb));
		},
		handleAlertOpen: (alert) => {
            dispatch(handleAlertOpen(alert));
		},
		handleProgressOpen: () => {
            dispatch(handleProgressOpen());
		},
        handleProgressClose: () => {
            dispatch(handleProgressClose());
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
		dashboardLayout: state.dashboard.layout,
	}
}

const styles = theme => ({
	root: {
		display: 'flex'
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	paper: {
		width: drawerWidth,
		top: 'auto',
		borderTop: '1px solid rgba(0, 0, 0, 0.12)'
	},
	content: {
		paddingTop: 0,
		flexGrow: 1,
		marginLeft: -drawerWidth
	},
	contentShift: {
		paddingTop: 0,
		marginLeft: 0
	},
	customizeAppbar: {
		color: '#D7DBDD'
	},
	customizeToolbar: {
		minHeight: 16,
		paddingLeft: '3px',
		paddingRight: 0
    },
	customizeToolbarControl: {
		minWidth: 8,
		padding: 0,
		margin: '6px'
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard));
