import React, { Component } from "react";
import Typography from '@mui/material/Typography';
import { createStyle } from "../styling";
import { Container, Row, Col } from 'reactstrap';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';

class Carousel extends Component {
	constructor(props) {
		super(props);
        this.state = {
			activeStep: 0,
            maxSteps: images.length
		};
    }

    handleNext() {
		this.setState(state => ({
			'activeStep': this.state.activeStep < this.state.maxSteps - 1 ? this.state.activeStep + 1 : 0
		}));
	};

    handleBack() {
		this.setState(state => ({
            'activeStep': this.state.activeStep > 0 ?  this.state.activeStep - 1 : this.state.maxSteps - 1
		}));
	};

    handleStepChange(step) {
    	this.setState(state => ({
            'activeStep': step
    	}));
    };

    render() {
        let self = this;
        const { classes } = this.props;

        const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

        return (
            <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
              <Paper
                square
                elevation={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 50,
                  pl: 2,
                  bgcolor: '#fafafa',
                }}
              >
                <Typography>{images[self.state.activeStep].label}</Typography>
              </Paper>
              <SwipeableViews
                axis={'theme.direction' === 'rtl' ? 'x-reverse' : 'x'}
                index={self.state.activeStep}
                onChangeIndex={this.handleStepChange.bind(this)}
                enableMouseEvents
              >
                {images.map((step, index) => (
                  <div key={step.label}>
                    {Math.abs(self.state.activeStep - index) <= 2 ? (
                      <Box
                        component="img"
                        sx={{
                          height: 255,
                          display: 'block',
                          maxWidth: 400,
                          overflow: 'hidden',
                          width: '100%',
                        }}
                        src={step.imgPath}
                        alt={step.label}
                      />
                    ) : null}
                  </div>
                ))}
              </SwipeableViews>
              <MobileStepper
                steps={this.state.maxSteps}
                position="static"
                activeStep={self.state.activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={this.handleNext.bind(this)}
                    disabled={this.props.noWrap && self.state.activeStep === this.state.maxSteps - 1}
                  >
                    Next
                    {'theme.direction' === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={this.handleBack.bind(this)} disabled={this.props.noWrap && self.state.activeStep === 0}>
                    {'theme.direction' === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Box>
        );
    }
}

const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bird',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  },
  {
    label: 'Goč, Serbia',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
];

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
	}
}

const styles = theme => ({
	root: {
		display: 'flex'
	},
	content: {
		paddingTop: 0,
		flexGrow: 1
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Carousel));
