import React, { Component } from 'react';
import './App.css';
import { Route } from "react-router-dom";
import LoginForm from "./LoginForm"
import Register from "./Register"
import RegisterComplete from "./RegisterComplete"
import ResetPassword from "./ResetPassword"

class Landing extends Component {
	constructor(props) {
		super(props)
		this.state ={
		}
	}

	render() {
		return (
            <div>
				<Route exact path="/" component={LoginForm}/>
				<Route exact path="/register" component={Register}/>
				<Route exact path="/registerComplete" component={RegisterComplete}/>
				<Route exact path="/requestPasswordReset" component={ResetPassword}/>
            </div>
        );
	}
}

export default Landing;
