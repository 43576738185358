import React, { Component } from "react";
import Typography from '@mui/material/Typography';
import { createStyle } from "../styling";
import { Container, Row, Col } from 'reactstrap';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import TaskCarousel from './TaskCarousel';
import Carousel from './Carousel';

import axios from 'axios';
import { hostname } from '../host';
import { connect } from 'react-redux';
import { handleAlertOpen, handleProgressOpen, handleProgressClose } from '../redux/actions/alertActions';
import { handleSetBreadcrumbName } from '../redux/actions/breadcrumbActions';

import { withStyles } from '@mui/styles';

class TaskBoard extends Component {
    constructor(props) {
		super(props);
		let self = this;

		this.handleSetBreadcrumbName = this.props.handleSetBreadcrumbName.bind(this);
		this.handleAlertOpen = this.props.handleAlertOpen.bind(this);
		this.handleProgressOpen = this.props.handleProgressOpen.bind(this);
        this.handleProgressClose = this.props.handleProgressClose.bind(this);

		this.state = {
		};

		self.handleProgressOpen();
		axios.get(hostname() + 'rest/taskBoard?projectId=' + props.match.params.projectId + '&boardId=' + props.match.params.boardId)
			// handle success
			.then(function (response) {
				self.setState(state => ({
                    'projectId': response.data.projectId,
					'projectName': response.data.projectName,
                    'boardId': response.data.boardId,
                    'boardName': response.data.boardName,
					'groups': response.data.groups
				}));
				self.handleSetBreadcrumbName({key: '/' + response.data.projectId, value: response.data.projectName});
                self.handleSetBreadcrumbName({key: '/' + response.data.boardId, value: response.data.boardName});
			})

			// handle error
			.catch(function (error) {
				self.setState(state => ({
					'project': undefined
				}));
				self.handleSetBreadcrumbName({key: '/' + props.match.params.id, value: 'Dashboard'});

				console.log(error);
				self.handleProgressClose();
				self.handleAlertOpen({ title: 'Error', severity: 'error', message: 'An error occurred and the server could not be reached.' });
			})

			// always executed
			.then(function () {
				self.handleProgressClose();
			});
	}

	render() {
        let self = this;
        const { classes } = this.props;

		return (
			<div style={{ marginBottom: "100px" }}>
				<div>
					<Typography variant="h3" style={createStyle('sectionHeader')}>{this.state.boardName}</Typography>
				</div>
                {	this.state.groups &&
					this.state.groups.map(function(group) {
						return (
                            <div style={{ marginBottom: '32px' }}>
                            <TaskCarousel projectId={self.state.projectId} groupName={group.title} items={group.items} noWrap/>
                            </div>
                        );
                    })
                }
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
		handleSetBreadcrumbName: (breadcrumb) => {
            dispatch(handleSetBreadcrumbName(breadcrumb));
		},
		handleAlertOpen: (alert) => {
            dispatch(handleAlertOpen(alert));
		},
		handleProgressOpen: () => {
            dispatch(handleProgressOpen());
		},
        handleProgressClose: () => {
            dispatch(handleProgressClose());
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
	}
}

const styles = theme => ({
	root: {
		display: 'flex'
	},
	content: {
		paddingTop: 0,
		flexGrow: 1
	},
    card: {
        // Provide some spacing between cards
        margin: 16,

        // Use flex layout with column direction for components in the card
        // (CardContent and CardActions)
        display: "flex",
        flexDirection: "column",

        // Justify the content so that CardContent will always be at the top of the card,
        // and CardActions will be at the bottom
        justifyContent: "space-between"
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskBoard));
