import React, { Component } from "react";
import Typography from '@mui/material/Typography';
import { createStyle } from "../styling";
import { Container, Row, Col } from 'reactstrap';
import './Dropzone.css'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import BackspaceIcon from '@mui/icons-material/Backspace';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TaskCard from './TaskCard';

import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';

class TaskCarousel extends Component {
	constructor(props) {
		super(props);
        this.state = {
			activeStep: 0,
            maxSteps: props.items ? props.items.length : 0,
			fileSubmissions: [],
		};
    }

	resetForm() {
        this.setState({
			fileSubmissions: []
		});
	}

    handleNext() {
		this.setState(state => ({
			'activeStep': this.state.activeStep < this.state.maxSteps - 1 ? this.state.activeStep + 1 : 0
		}));
	};

    handleBack() {
		this.setState(state => ({
            'activeStep': this.state.activeStep > 0 ?  this.state.activeStep - 1 : this.state.maxSteps - 1
		}));
	};

    handleStepChange(step) {
    	this.setState(state => ({
            'activeStep': step
    	}));
    };

	fileValidation(acceptedFiles) {
        let id = 'file';

        //if (acceptedFiles[0].name.endsWith(".pdf") || acceptedFiles[0].name.endsWith(".doc") || acceptedFiles[0].name.endsWith(".docx")) {
            this.setState({
                fileSubmissions: acceptedFiles,
                [id + 'InputError']: false
    		});
        /*}
        else {
            this.setState({
                fileSubmissions: [],
                [id + 'InputError']: true
    		});
        }*/
    };

    removeFile(args) {
        this.setState({
            fileInputDirty: true,
            fileSubmissions: []
		})
    };

	isValid() {
        return (this.state.fileSubmissions.length === 1
		)
	};

	handleFormSubmit = event => {
        let self = this;

        if (this.isValid()) {
		}
    };

    render() {
        let self = this;
        const { classes } = this.props;

        const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

        return (
            <Container>
				<Typography variant="h4" style={createStyle('sectionHeader')}>{this.props.groupName}</Typography>

            	<Box sx={{ maxWidth: 400, flexGrow: 1 }}>
					{ !self.props.items &&
    				<Paper square elevation={1} sx={{ display: 'flex', alignItems: 'center', height: 50, pl: 2, bgcolor: '#fafafa' }}>
						<Typography style={createStyle('siteTypography')}>There are no open tasks.</Typography>
    				</Paper>
					}
					{ self.props.items &&
					<SwipeableViews axis={'theme.direction' === 'rtl' ? 'x-reverse' : 'x'} index={self.state.activeStep} onChangeIndex={this.handleStepChange.bind(this)} enableMouseEvents>
						{ self.props.items.map((task, index) => (
							<TaskCard projectId={self.props.projectId} task={task}/>
						))}
						{ self.props.items.map((task, index) => (
    					<Card className={classes.card}>
                    		<CardContent>
                            	<Typography style={createStyle('navTypography')} variant="h5" component="h5">
                                	{task.name}
                            	</Typography>

								{ task.status &&
								<div style={{ marginTop: '2px', marginBottom: '2px' }}>
									<Typography style={createStyle('monoTypography')} sx={{ color: '#585A5C', paddingRight: '5px', paddingTop: '5px', paddingBottom: '5px' }} display="inline">
										Status:
									</Typography>
									<Typography style={createStyle('monoTypography')} sx={{ backgroundColor: task.status.color, color: '#FFFFFF', padding: '5px' }} display="inline">
                                		{task.status.label}
                            		</Typography>
								</div>
								}

								{ task.links && task.links.map((link, index) => (
								<div>
									<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
										<Link style={createStyle('navLink')} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} to={{ pathname: link.url }} target="_blank">
										{link.text.substring(link.text.lastIndexOf('/') + 1)}
										</Link>
									</div>

									{ false &&
									<Link to={{ pathname: link.url }} target="_blank">
									<IconButton edge="start" aria-label="menu" size="large">
										<DescriptionIcon fontSize="large"/>
									</IconButton>
									</Link>
									}

									{ false &&
									<Dropzone onDrop={this.fileValidation.bind(this)}>
										{({ getRootProps, getInputProps }) => (
										<div {...getRootProps({ className: "dropzone" })}>
											<input {...getInputProps()} />
											<Typography style={createStyle('navBackground')} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
												{link.text}
											</Typography>
										</div>
										)}
									</Dropzone>
									}

									{ false &&
									<IconButton edge="start" aria-label="menu" size="medium">
										<UploadFileIcon fontSize="medium"/>
									</IconButton>
									}


								</div>
								))}

								{false && (this.state.fileSubmissions) &&
	                            	this.state.fileSubmissions.map(fileSubmission => (
	                                	<div>
	                                    	{fileSubmission.name}
	                                    	<Button color="inherit" onClick={this.removeFile.bind(this)}>
	                                        	<FontAwesomeIcon icon={faTrash} size="sm" color="#005fcc" style={{ "marginLeft": "8px" }}/>
	                                    	</Button>
	                                	</div>
	                            	))
	                        	}

								<Dropzone onDrop={this.fileValidation.bind(this)}>
									{({ getRootProps, getInputProps }) => (
									<div {...getRootProps({ className: "dropzone" })}>
										<input {...getInputProps()} key={"dropzone" + task.id} />


										{(this.state.fileSubmissions) &&
			                            	this.state.fileSubmissions.map(fileSubmission => (

										<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
											<Typography style={createStyle('navTypography')} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
											{fileSubmission.name}
											</Typography>
										</div>

											))
	                        			}

										{this.state.fileSubmissions.length === 0 &&

										<Typography style={createStyle('navBackground')} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
										Drag file here or click to browse
										</Typography>

										}
									</div>
									)}
								</Dropzone>

								{this.state.fileSubmissions.length === 1 &&
									<div style={{ "display": "flex" }}>
										<span style={{ "flexGrow": "1" }}/>

										<IconButton edge="start" aria-label="menu" size="medium" onClick={this.removeFile.bind(this)} disabled={!this.isValid()}>
											<BackspaceIcon fontSize="medium"/>
										</IconButton>
										<IconButton edge="start" aria-label="menu" size="medium" onClick={() => {this.handleFormSubmit()}} disabled={!this.isValid()}>
											<UploadFileIcon fontSize="medium"/>
										</IconButton>
									</div>
								}


                        	</CardContent>
							<CardActions>

								<div style={{ "display": "flex" }}>
									<span style={{ "flexGrow": "1" }}>
									</span>


									{ false &&
									<Link style={createStyle('navLink')} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} to="" target="_blank">
									Upload
									</Link>
									}

									{ false &&
									<NavLink color="inherit" to={"sendemail"} key={"hello"}>
										<Typography className="title" style={createStyle('navTypography')} key={'world'}>
										Open
										</Typography>
									</NavLink>
									}
								</div>

							</CardActions>
                    	</Card>
						))}
					</SwipeableViews>
					}
					{ self.props.items &&
    				<MobileStepper sx={{ bgcolor: '#fafafa' }} steps={this.state.maxSteps} position="static" activeStep={self.state.activeStep}
							nextButton={
								<Button size="small" onClick={this.handleNext.bind(this)} disabled={this.props.noWrap && self.state.activeStep === this.state.maxSteps - 1}>
									Next
									{'theme.direction' === 'rtl' ? (
									<KeyboardArrowLeft />
									) : (
									<KeyboardArrowRight />
        							)}
								</Button>
							}
							backButton={
								<Button size="small" onClick={this.handleBack.bind(this)} disabled={this.props.noWrap && self.state.activeStep === 0}>
									{'theme.direction' === 'rtl' ? (
        							<KeyboardArrowRight />
        							) : (
        							<KeyboardArrowLeft />
        							)}
        							Back
        						</Button>
    						}
    				/>
					}
    			</Box>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
	}
}

const styles = theme => ({
	root: {
		display: 'flex'
	},
    card: {
        backgroundColor: '#f1f3f4',
        height: '300px',
        borderRadius: '24px'
    },
	content: {
		paddingTop: 0,
		flexGrow: 1
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskCarousel));
