import React, { Component } from 'react';
import { Link } from "react-router-dom";

class RegisterComplete extends Component {

	render() {
		return (
            <div className="font-styled font-fine">
				Your registration is complete! To get started, <Link to="/">login</Link>.
            </div>
        );
    }
}

export default RegisterComplete;
