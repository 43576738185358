// Action Types

export const SET_BREADCRUMB_NAME = 'SET_BREADCRUMB_NAME'

// Other Constants


// Action creators

export function handleSetBreadcrumbName(breadcrumb) {
    return { type: SET_BREADCRUMB_NAME, payload: { key: breadcrumb.key, value: breadcrumb.value } }
}
