import { OPEN_DOCUMENT, CLOSE_DOCUMENT, LAYOUT_CHANGE } from '../actions/dashboardActions'

import WalkaboutWidget from '../../project/WalkaboutWidget';
import DocumentWidget from '../../project/DocumentWidget';

const initialState = {
    layout: [
        { i: 'bugfix', x: 7, y: 0, w: 1, h: 1 }
        /*{i: 'a', x: 0, y: 0, w: 1, h: 2, static: true},
        {i: 'b', x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4},
        {i: 'c', x: 4, y: 0, w: 1, h: 2}*/
    ]
}

export default function dashboard(state = initialState, action) {
    var layout = null;
    switch (action.type) {
        case LAYOUT_CHANGE:
            var newState = {...state};
            newState.layout = action.payload.layout;
            return newState;

        case OPEN_DOCUMENT:
            var newState = {...state};

            layout = [...state.layout];
            layout.push(
                {i: action.payload.document.id, x: 0, y: 0, w: 5, h: 10, minW: 1, minH: 2, content: DocumentWidget, title: action.payload.document.title}
                //{i: action.payload.document.id, x: 0, y: 0, w: 5, h: 10, minW: 1, minH: 2, content: WalkaboutWidget, title: action.payload.document.title}
            );

            // TODO do something better with the bugfix widget

            newState.layout = layout;
            return newState;

        case CLOSE_DOCUMENT:
            var newState = {...state};

            // TODO do something better with the bugfix widget

            layout = state.layout.filter(function(widget) {
                return action.payload.document.id !== widget.i;
            });

            newState.layout = layout;
            return newState;
        default:
            return state
    }
}
