import React, { Component } from 'react';
import '../App.css';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { createStyle } from "../styling";

import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import WorkIcon from '@mui/icons-material/Work';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import PlaceIcon from '@mui/icons-material/Place';
import HelpIcon from '@mui/icons-material/Help';
import CollectionsIcon from '@mui/icons-material/Collections';
import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FaceIcon from '@mui/icons-material/Face';
import TimelineIcon from '@mui/icons-material/Timeline';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LaunchIcon from '@mui/icons-material/Launch';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import Button from '@mui/material/Button';

import { connect } from 'react-redux';
import { handleAlertOpen, handleProgressOpen, handleProgressClose } from '../redux/actions/alertActions';
import { handleOpenDocument } from '../redux/actions/dashboardActions';

import axios from 'axios';
import { hostname } from '../host';

import { withStyles } from '@mui/styles';

class StyledTreeItem extends Component {
	constructor(props) {
		super(props);
		this.handleOpenDocument = props.handleOpenDocument.bind(this);
		this.handleProgressOpen = props.handleProgressOpen.bind(this);
		this.handleProgressClose = props.handleProgressClose.bind(this);

		this.state = {
            documents: props.documents
		};
	}

	newDocument(event) {
		let self = this;
        let primaryContextId = this.props.nodeId;

		let formData = new FormData();
		formData.append('title', 'New Document');
		formData.append('primaryContext', primaryContextId);
		formData.append('projectId', this.props.projectId);

		// Submit the form
		self.handleProgressOpen();
		axios.post(
			'/rest/documents',
			formData,
			{
				headers: { 'Content-Type': 'multipart/form-data' }
			}
		)

		// handle success
		.then(function (response) {
			self.handleAlertOpen({ title: 'Success', severity: 'success', message: 'Your document was successfully created.' });
		})

		// handle error
		.catch(function (error) {
			self.handleAlertOpen({ title: 'Error', severity: 'error', message: 'An error occurred and your document could not be created.' });
		})

		// always executed
		.then(function () {
			self.handleProgressClose();
		});
	}

	openDocumentFromMenu(event) {
		this.setState(state => ({
			'anchorEl': undefined,
			'open': false
		}));

		// TODO pass document here!!!
        let document = {
            title: this.props.labelText,
            id: this.props.nodeId
        }
		this.props.handleOpenDocument(document);
	}

	handleClick(event) {
		let currentTarget = event.currentTarget; // because otherwise becomes null async
		this.setState(state => ({
			'anchorEl': currentTarget,
			'open': true
		}));
	}

	handleClose() {
		this.setState(state => ({
			'anchorEl': undefined,
			'open': false
		}));
	}

	render() {
		let self = this;
        let maxWidth = (MAX_LINE_WIDTH - (this.props.nodeDepth ? this.props.nodeDepth : 0) * THEME_SPACING_2) + 'px';
		const { classes, labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = this.props;

		return (
			<TreeItem
                onLabelClick={(event) => {event.preventDefault();}}
				label={
					<div className={classes.labelRoot}>
						<LabelIcon color="inherit" className={classes.labelIcon}/>
                        <div style={{ flexGrow: 12 }}>
                            <Typography noWrap="true" variant="body" style={{

                                maxWidth: maxWidth,
                                fontWeight: 'inherit',
                                fontSize: '12px',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                overflow: 'hidden',
								paddingTop: '2px',
								verticalAlign: 'bottom'

                            }}>
                                {labelText}
				            </Typography>
                        </div>
						<Typography variant="caption" color="inherit">
							{labelInfo}
						</Typography>

						<Button style={{ padding: "2px 6px 6px 6px", minWidth: "0", verticalAlign: "top", color: "#585858", borderRadius: "24px", float: "right" }} aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick.bind(this)}>
							<MoreVertIcon fontSize="small"/>
						</Button>
						<Menu
							id="tree-item-menu"
							anchorEl={self.state.anchorEl}
							getContentAnchorEl={null}
							anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
							transformOrigin={{ vertical: "top", horizontal: "left" }}
							keepMounted
							open={self.state.open}
							onClose={self.handleClose.bind(self)}
						>
							<MenuItem onClick={self.newDocument.bind(self)}>
								<ListItemIcon style={{ minWidth: '24px' }}>
									<NoteAddIcon/>
								</ListItemIcon>
								<ListItemText style={{ fontSize: '12px' }} primary="New..."/>
							</MenuItem>
							<MenuItem onClick={self.openDocumentFromMenu.bind(self)}>
								<ListItemIcon style={{ minWidth: '24px' }}>
									<LaunchIcon/>
								</ListItemIcon>
								<ListItemText style={{ fontSize: '12px' }} primary="Open"/>
							</MenuItem>
						</Menu>
					</div>
				}
				style={{
		          '--tree-view-color': color,
		          '--tree-view-bg-color': bgColor
		        }}
		        classes={{
		          root: classes.root,
		          content: classes.content,
		          expanded: classes.expanded,
		          selected: classes.selected,
		          group: classes.group,
		          label: classes.label,
		        }}
		        {...other}

			>

            {
                (this.props.documents) &&
                this.props.documents.map((child) => {
                    return (
                        <StyledTreeItem handleOpenDocument={this.handleOpenDocument} handleProgressOpen={this.handleProgressOpen} handleProgressClose={this.handleProgressClose} classes={classes}
                            nodeDepth={this.props.nodeDepth ? + this.props.nodeDepth + 1 : 1}
                            documents={child.documents}
                            nodeId={child.id}
							projectId={this.props.projectId}
                            labelText={child.title}
                            labelIcon={child.id === TRASH ? DeleteIcon :
                                child.def === "File" ? DescriptionIcon :
                                child.def === "Folder" ? FolderIcon :
                                child.def === "Region" ? WallpaperIcon :
                                child.def === "Location" ? PlaceIcon :
                                child.def === "Campaign" ? CollectionsIcon :
                                child.def === "Setting" ? FilterBAndWIcon :
                                child.def === "PlayerCharacter" ? FaceIcon :
                                child.def === "NonPlayerCharacter" ? PersonIcon :
                                child.def === "CharacterTemplate" ? PersonOutlineIcon :
                                child.def === "View" ? TimelineIcon :
                                child.def === "RangedWeapon" ? DoubleArrowIcon :
                                HelpIcon}
                        />
                    );
                })
            }

			</TreeItem>
        )
	}
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
		handleOpenDocument: (document) => {
			dispatch(handleOpenDocument(document));
		},
		handleProgressOpen: () => {
            dispatch(handleProgressOpen());
		},
        handleProgressClose: () => {
            dispatch(handleProgressClose());
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
	}
}

/* Can't figure out how to get both theme and this in one place so here we are */
const THEME_SPACING_2 = 4;
const MAX_LINE_WIDTH = 134;

const TRASH = "-2147483648";

const styles = theme => ({
	root: {
        '&:hover > $content': {
        },
        '&:focus > $content, &$selected > $content': {
            color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
        paddingLeft: 'calc(' + /*theme.spacing(2)*/ THEME_SPACING_2 + 'px)'
    },
    content: {
        '$expanded > &': {
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    labelIcon: {
    },
    labelText: {
        fontWeight: 'inherit',
        fontSize: '12px',

        /* TODO calculate maxWidth based on how many levels deep we are */
        /*maxWidth: 'calc(100px - ' + this.props.nodeDepth + ' * ' + theme.spacing(2) + 'px)',*/

        textOverflow: 'ellipsis',
        display: 'inline-block',
        overflow: 'hidden'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StyledTreeItem));
