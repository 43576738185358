// Action Types

export const SESSION_ACTION = 'SESSION_ACTION'

// Other Constants

export const SessionStatus = {
    LOGGED_IN: 'LOGGED_IN',
    NOT_LOGGED_IN: 'NOT_LOGGED_IN'
}

// Action creators

export function setSessionData(auth) {
    return { type: SESSION_ACTION, sessionStatus: SessionStatus.LOGGED_IN, username: auth.login }
}

export function clearSessionData(error) {
    return { type: SESSION_ACTION, sessionStatus: SessionStatus.NOT_LOGGED_IN, loginError: error ? error.message : null }
}
