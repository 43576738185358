import React, { Component } from 'react';
import { connect } from 'react-redux';

class RegisterResults extends Component {

	render() {
		return (
            <div className="font-styled font-fine">
                <div>You're almost there!</div>
                <div>Check your email (and spam folder) at <span className="bold">{this.props.email}</span> to finish creating your account.</div>
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
		email: state.form?.registerForm?.values?.email
	};
};

export default connect(mapStateToProps)(RegisterResults);
