import React, { Component } from 'react';
import './App.css';
import { createStyle } from "./styling";

import Landing from "./Landing";
import Home from "./Home";
import Messages from "./Messages";
import CreateProjectForm from './project/CreateProjectForm';
import Dashboard from './project/Dashboard';
import Monday from './project/Monday';
import TaskBoard from './project/TaskBoard';

import { handleCartClose, handleCartOpen } from './redux/actions/cartActions';
import { handleAlertClose } from './redux/actions/alertActions';

import { Helmet } from "react-helmet";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Badge from '@mui/material/Badge';

import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import Button from '@mui/material/Button';
import { Route, NavLink, Link  } from "react-router-dom";

import Box from '@mui/material/Box';
import { signOut } from './auth/AuthService';
import { clearSessionData } from './redux/actions';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { SessionStatus } from './redux/actions'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";

import { Alert, AlertTitle } from '@mui/material';
import Collapse from '@mui/material/Collapse';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { connect } from 'react-redux';

class App extends Component {
	constructor(props) {
		super(props);
		this.getBreadcrumbName = this.getBreadcrumbName.bind(this);

		this.handleCartClose = this.props.handleCartClose.bind(this);
        this.handleCartOpen = this.props.handleCartOpen.bind(this);
		this.handleAlertClose = this.props.handleAlertClose.bind(this);

		this.clearSessionData = this.props.clearSessionData.bind(this);
		this.state = {
		};
	}

	handleSignOut() {
		signOut().then(signOutAttempt => this.clearSessionData(signOutAttempt));
	};

	handleMenuOpen = event => {
    	this.setState({ anchorEl: event.currentTarget });
	};

    handleMenuClose() {
    	this.setState(state => ({
			anchorEl: null
		}));
    };

	handleMenuLogout() {
		this.handleMenuClose();
		this.handleSignOut();
    };

	toggleDrawer(anchor, open) {
		this.setState(state => ({
			[anchor]: open
		}));
    };

	// The background-color contained here sets the background for the entire app!
	render() {
		return (
			<div className="App">

				<Helmet>
					<style>{'body { background-color: #fafafa; }'}</style>
					<title>Newfangle Press</title>
					<meta name="google-site-verification" content="NGCtSxuSzrQED1Pm4oA36ZQnW5Q4U_5Cqidht9CYiB4"/>

					<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
					<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
					<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
					<link rel="manifest" href="/site.webmanifest"/>
					<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
					<meta name="msapplication-TileColor" content="#da532c"/>
					<meta name="theme-color" content="#ffffff"/>
				</Helmet>

				<Backdrop style={{ "color": "#a22f7d", "zIndex": "1200" }} open={this.props.isProgressOpen}>
                    <CircularProgress color="inherit" />
                </Backdrop>

				<div className="only-mobile">
					<AppBar position="static" style={{ background: '#f1f3f4' }}  elevation={0}>
						<Toolbar>
						{
							(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
							<Box>

							<IconButton onClick={this.toggleDrawer.bind(this, 'navMenu', true)} edge="start" className="menuButton" color="#585A5C" aria-label="menu">
								<MenuIcon className="only-mobile"/>
							</IconButton>
							<Drawer anchor='left' open={this.state['navMenu']} onClose={this.toggleDrawer.bind(this, 'navMenu', false)}>
								<List>
									<NavLink className="nav-item" to={{ pathname: '/', state: 'refreshStore' }}>
										<ListItem button key="home">
											<ListItemText disableTopography="true">
												<Typography variant="h6" className="title.dark" style={createStyle('navTypography')}>Home</Typography>
											</ListItemText>
										</ListItem>
									</NavLink>
									<NavLink className="nav-item" to={{ pathname: '/messages', state: 'refreshStore' }}>
										<ListItem button key="messages">
											<ListItemText disableTopography="true">
												<Typography variant="h6" className="title.dark" style={createStyle('navTypography')}>Messages</Typography>
											</ListItemText>
										</ListItem>
									</NavLink>
									<NavLink onClick={this.handleSignOut.bind(this)} className="nav-item" to="">
										<ListItem component="a" button key="logout">
											<ListItemText disableTopography="true">
												<Typography variant="h6" className="title.dark" style={createStyle('navTypography')}>Logout</Typography>
											</ListItemText>
										</ListItem>
									</NavLink>
								</List>
							</Drawer>
							</Box>
							}

							<NavLink className="nav-item" to="/">
							<div style={{ "flexGrow": "1" }}>
								<img src={require('./logo/NP_logo_A_bloody.png')} width="265" alt="Newfangle Press"/>
							</div>
							</NavLink>
						</Toolbar>
					</AppBar>
				</div>

				<AppBar className="not-mobile" position="sticky" style={{ background: '#f1f3f4' }} elevation={0}>
					<Toolbar>
						<NavLink className="nav-item" to="/">
							<img src={require('./logo/NP_logo_A_bloody.png')} width="265" alt="Newfangle Press"/>
						</NavLink>

						<Box display='flex' flexGrow={1}>
						</Box>

						{
							(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
							<Button style={{ color: '#585A5C' }} component={NavLink} to="/messages">
								<Badge badgeContent={0} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
									<MessageIcon fontSize="medium"/>
								</Badge>
							</Button>
						}
						{
							(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
							<Button style={{ color: '#585A5C' }} onClick={this.handleMenuOpen.bind(this)}>
								<AccountBoxIcon fontSize="medium"/>
							</Button>
						}
						{
							(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
							<Menu id="account-menu" anchorEl={this.state.anchorEl}
									open={this.state.anchorEl} onClose={this.handleMenuClose.bind(this)}
								MenuListProps={{ 'aria-labelledby': 'basic-button' }}
							>
								<NavLink className="nav-item" to="">
								<MenuItem onClick={this.handleMenuClose.bind(this)}>
									<Typography className="title" style={createStyle('navTypography')}>My Account</Typography>
								</MenuItem>
								</NavLink>
								<NavLink className="nav-item" to="">
								<MenuItem onClick={this.handleMenuLogout.bind(this)}>
									<Typography className="title" style={createStyle('navTypography')}>Logout</Typography>
								</MenuItem>
								</NavLink>
							</Menu>
						}
					</Toolbar>
				</AppBar>

				<Collapse in={this.props.isAlertOpen} style={{ position: "fixed", zIndex: "1742" }}>
					<Alert severity={this.props.alertSeverity}
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={this.handleAlertClose}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						<AlertTitle>{this.props.alertTitle}</AlertTitle>
						{this.props.alertMessage}
					</Alert>
				</Collapse>

				{
					(SessionStatus.LOGGED_IN !== this.props.sessionStatus) &&
					<Route path="/">
						<Landing/>
					</Route>
				}

				{
					(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
					<Route>
						{({ location }) => {
							const pathnames = location.pathname.split('/').filter((x) => x);

							return (
								<Breadcrumbs aria-label="breadcrumb">
									<NavLink color="inherit" to="/">
										<Typography className="title" style={createStyle('navTypography')}>Home</Typography>
									</NavLink>
									{pathnames.map((value, index) => {
										const last = index === pathnames.length - 1;
										const to = `/${pathnames.slice(0, index + 1).join('/')}`;
										const breadcrumb = '/' + pathnames[index];

										return last ? (
											<Typography className="title" style={createStyle('navTypography')} key={breadcrumb}>
											{this.getBreadcrumbName(breadcrumb)}
											</Typography>
										) : (
											<NavLink color="inherit" to={to} key={breadcrumb}>
												<Typography className="title" style={createStyle('navTypography')} key={breadcrumb}>
												{this.getBreadcrumbName(breadcrumb)}
												</Typography>
											</NavLink>
										);
									})}
								</Breadcrumbs>
							);
						}}
					</Route>
				}

				{
					(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
					<Route exact path="/">
						<Home/>
					</Route>
				}
				{
					(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
					<Route path="/messages" component={Messages}/>
				}
				{
					(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
					<Route path="/create-project" component={CreateProjectForm}/>
				}
				{
					(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
					<Route exact path="/projects" component={Home}/>
				}
				{
					(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
					<Route exact path="/projects/:id" component={Dashboard}/>
				}
				{
					(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
					<Route exact path="/projects/:id/monday" component={Monday}/>
				}
				{
					(SessionStatus.LOGGED_IN === this.props.sessionStatus) &&
					<Route exact path="/projects/:projectId/monday/:boardId" component={TaskBoard}/>
				}

				<AppBar position="fixed" style={{ top: "auto", bottom: "0", backgroundColor: "rgb(250,250,250,1)", textAlign: "center" }} elevation={0} className="footerBar">
					<Toolbar style={{ textAlign: "center" }}>
						<a href="https://facebook.com/newfanglepress" style={{ marginLeft: "auto", marginRight: "4px" }}>
							<FontAwesomeIcon icon={faFacebookSquare} size="2x" color="#585A5C"/>
						</a>
						<a href="https://twitter.com/NewfanglePress" style={{ marginRight: "auto", marginLeft: "4px" }}>
							<FontAwesomeIcon icon={faTwitterSquare} size="2x" color="#585A5C"/>
						</a>
					</Toolbar>
					<div style={{ backgroundColor: "#585A5C" }}>
						<Typography style={createStyle('siteTypography')}>Copyright &#169; 2020, 2024 Newfangle Media LLC &#9670; Cincinnati, Ohio &#9670; USA</Typography>
					</div>
				</AppBar>
			</div>
		);
	};

	getBreadcrumbName(to) {
		var retVal = '\u2026';

		if (this.props.breadcrumbs[to]) {
			retVal = this.props.breadcrumbs[to];
		}

		return retVal;
	};

}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
		clearSessionData: (signOutAttempt) => {
			dispatch(clearSessionData());
		},
		handleCartClose: () => {
            dispatch(handleCartClose());
		},
        handleCartOpen: () => {
            dispatch(handleCartOpen());
		},
		handleAlertClose: () => {
			dispatch(handleAlertClose());
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
		alertTitle: state.alert.alertTitle,
        alertSeverity: state.alert.alertSeverity,
        alertMessage: state.alert.alertMessage,
        isAlertOpen: state.alert.isAlertOpen,
		isProgressOpen: state.alert.isProgressOpen,
		sessionStatus: state.session.sessionStatus,
		checkout: state.cart.checkout,
        isCartOpen: state.cart.isCartOpen,
		breadcrumbs: state.breadcrumbs
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
