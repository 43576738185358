import React, { Component } from "react";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';

import { handleAlertOpen, handleProgressOpen, handleProgressClose } from '../redux/actions/alertActions';

class RejectDialog extends Component {
	constructor(props) {
		super(props);
        this.state = {
			open: false
        };
    }

    open() {

    }

    handleClose() {

    }

    render() {
        let self = this;
        const { classes } = this.props;
        return (

            <Dialog
                    open={this.open}
                    onClose={this.handleClose}
                    PaperProps={{
                      component: 'form'
                    }}
                  >
                    <DialogTitle>Reject</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Not currently in use.
                      </DialogContentText>
                      <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button >Cancel</Button>
                      <Button type="submit">Subscribe</Button>
                    </DialogActions>
                  </Dialog>


        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleAlertOpen: (alert) => {
            dispatch(handleAlertOpen(alert));
		},
		handleProgressOpen: () => {
            dispatch(handleProgressOpen());
		},
        handleProgressClose: () => {
            dispatch(handleProgressClose());
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
	}
}

const styles = theme => ({
	root: {
		display: 'flex'
	},
    card: {
        backgroundColor: '#f1f3f4',
        height: '300px',
        borderRadius: '24px'
    },
	content: {
		paddingTop: 0,
		flexGrow: 1
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RejectDialog));
