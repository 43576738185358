import { SET_BREADCRUMB_NAME } from '../actions/breadcrumbActions'

const initialState = {
    '/messages': 'Messages',
    '/create-project': 'Create a project',
    '/monday': 'Task Boards',
    '/projects': 'Projects'
}

export default function breadcrumbs(state = initialState, action) {
    switch (action.type) {
        case SET_BREADCRUMB_NAME:
            var newState = {...state};
            newState[action.payload.key] = action.payload.value;
            return newState;
        default:
            return state
    }
}
