import React, { Component } from "react";
import Typography from '@mui/material/Typography';
import { createStyle } from "./styling";
import { Container, Row, Col } from 'reactstrap';

class Messages extends Component {
	render() {
		return (
			<Container>
				<Row>
					<Typography variant="h3" style={createStyle('sectionHeader')}>Messages</Typography>
				</Row>
				<Row>
					<Typography style={createStyle('siteTypography')}>You have no messages.</Typography>
				</Row>
			</Container>
		);
	}
}

export default Messages;
