import React, { Component } from 'react';
import '../App.css';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { createStyle } from "../styling";

import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import StyledTreeItem from './StyledTreeItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import WorkIcon from '@mui/icons-material/Work';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import LaunchIcon from '@mui/icons-material/Launch';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import Button from '@mui/material/Button';

import { connect } from 'react-redux';
import { handleOpenDocument } from '../redux/actions/dashboardActions';

import axios from 'axios';
import { hostname } from '../host';

import { withStyles } from '@mui/styles';

class Explorer extends Component {
	constructor(props) {
		super(props);
		let self = this;

		this.state = {
		};

	}

	handleChange(event, nodes) {
	}

	render() {
		const { classes } = this.props;

	    return (
			<TreeView
				className={classes.root}
				defaultExpanded={['3']}
				defaultCollapseIcon={<ArrowDropDownIcon />}
				defaultExpandIcon={<ArrowRightIcon />}
				defaultEndIcon={<div style={{ width: 24 }} />}
				onNodeToggle={this.handleChange.bind(this)}
			>
				<StyledTreeItem projectId={this.props.projectId} documents={this.props.documents} nodeId="/" labelText="All documents" labelIcon={WorkIcon} classes={classes}/>
			</TreeView>
        )
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
		handleOpenDocument: (document) => {
			dispatch(handleOpenDocument(document));
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
		documents: state.explorer.tree
	}
}

const styles = theme => ({
	root: {
	    '&:hover > $content': {
	    },
	    '&:focus > $content, &$selected > $content': {
	      color: 'var(--tree-view-color)',
	    },
	    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
	      backgroundColor: 'transparent',
	    },
	  },
	  content: {
	  },
	  group: {
	    marginLeft: 0,
	    '& $content': {
	    },
	  },
	  expanded: {},
	  selected: {
	  },
	  label: {
	    fontWeight: 'inherit',
	    color: 'inherit',
	  },
	  labelRoot: {
	    display: 'flex',
	    alignItems: 'center'
	  },
	  labelIcon: {
	  },
	  labelText: {
	    fontWeight: 'inherit',
		fontSize: '12px',
	    flexGrow: 1,
	  }

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Explorer));
