import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Alert, AlertTitle } from '@mui/material';
import Button from '@mui/material/Button';
import { renderTextField } from './forms';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { login } from './auth/AuthService';
import { setSessionData, clearSessionData } from './redux/actions';
import { handleAlertOpen, handleProgressOpen, handleProgressClose } from './redux/actions/alertActions';
import Typography from '@mui/material/Typography';
import { createStyle } from "./styling";

class LoginForm extends Component {
	constructor(props) {
		super(props);

        this.handleAlertOpen = this.props.handleAlertOpen.bind(this);
        this.handleProgressOpen = this.props.handleProgressOpen.bind(this);
        this.handleProgressClose = this.props.handleProgressClose.bind(this);
	}

	handleSubmit(credentials) {
		this.handleProgressOpen();
		login(credentials).then(loginAttempt => this.props.handleLoginAttempt(loginAttempt));
	}

	render() {
		let loginAlert = this.props.loginError ?
			(<Alert severity="warning">
				<AlertTitle>We couldn’t log you in.</AlertTitle>
				{this.props.loginError}
			</Alert>)
		: null;

		return (
			<form onSubmit={this.props.handleSubmit(this.handleSubmit.bind(this))}>
				{ loginAlert }
        		<div className="login-form-row">
					<Field name="email" component={renderTextField} label="Email"/>
				</div>
    			<div className="login-form-row-extra">
					<Field name="password" component={renderTextField} label="Password" type="password"/>
				</div>
				<div className="login-form-row-extra">
					<Button type="submit" variant="contained" color="primary">Login</Button>
                </div>

				<div className="font-styled font-fine login-form-row">
                	<div>
						<Typography style={createStyle('siteTypography')}>
						Don't have an account? <Link to="/register">Create an account</Link> now.
						</Typography>
					</div>
					<div>
						<Typography style={createStyle('siteTypography')}>
						<Link to="/requestPasswordReset">Forgot your password?</Link>
						</Typography>
					</div>


                </div>
			</form>
		);
	}
}

// Decorate the form component
LoginForm = reduxForm({
	form: 'loginForm' // a unique name for this form
})(LoginForm);

const mapStateToProps = state => {
	return {
		sessionStatus: state.session.sessionStatus,
		username: state.session.username,
		loginError: state.session.loginError
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
		handleLoginAttempt: (loginAttempt) => {
			dispatch(handleProgressClose());
			if (loginAttempt.error) {
				dispatch(clearSessionData(loginAttempt.error));
			}
			else {
				dispatch(setSessionData(loginAttempt.account));
			}
		},
		handleAlertOpen: (alert) => {
            dispatch(handleAlertOpen(alert));
		},
		handleProgressOpen: () => {
            dispatch(handleProgressOpen());
		},
        handleProgressClose: () => {
            dispatch(handleProgressClose());
		}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
