import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { register } from './auth/AuthService';
import { renderTextField } from './forms';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import RegisterResults from "./RegisterResults"
import Typography from '@mui/material/Typography';
import { createStyle } from "./styling";

const email = (value) => {
	let retVal = value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(value)
		? 'Invalid email address'
		: undefined
	return retVal;
}

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPassword: false
		};
		this.togglePasswordField = this.togglePasswordField.bind(this);
	}

	togglePasswordField() {
		this.setState(state => ({
			showPassword: !state.showPassword
		}));
	}

	handleSubmit(data) {
		register(data);
	}

	render() {
		if (this.props.submitSucceeded) {
			return (<RegisterResults/>);
		}
		else {
		return (
			<form onSubmit={this.props.handleSubmit(this.handleSubmit.bind(this))}>
            	<div>
					<div className="login-form-row">
						<Typography style={createStyle('siteTypography')}>Let's create your account.</Typography>
					</div>

					<div className="login-form-row">
						<Field name="firstName" component={renderTextField} label="First Name" required/>
					</div>

					<div className="login-form-row">
						<Field name="lastName" component={renderTextField} label="Last Name" required/>
					</div>

					<div className="login-form-row">
						<Field name="email" type="email" component={renderTextField} label="Email" validate={email} required/>
					</div>

					<div className="login-form-row-extra">
						<Field
							name="password"
							component={renderTextField}
							label="Password"
							type={ this.state.showPassword ? "text" : "password" }
							InputProps={{
								endAdornment: <IconButton onClick={this.togglePasswordField}>
									{ this.state.showPassword ? <Visibility/> : <VisibilityOff/> }
								</IconButton>
							}}
							required
						/>
					</div>

					<div className="login-form-row">
						<Button type="submit" variant="contained" color="primary">Create Account</Button>
					</div>
            	</div>
			</form>
        );
		}
    }
}

// Decorate the form component
Register = reduxForm({
	form: 'registerForm' // a unique name for this form
})(Register);


const mapStateToProps = state => {
	return {
		currentUser: state.currentUser,
		submitSucceeded: state.form?.registerForm?.submitSucceeded
	};
};

export default connect(mapStateToProps)(Register);
