import React, { Component } from 'react';
import './App.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { renderTextField } from './forms';
import { Field, reduxForm } from 'redux-form';
import Typography from '@mui/material/Typography';
import { createStyle } from "./styling";

class ResetPassword extends Component {
	constructor(props) {
		super(props)
		this.state ={
		}
	}

	render() {
		return (
			<div>
				<div className="login-form-row">
					<Typography style={createStyle('siteTypography')}>
					<div>Forgot your password?</div>
					<div>No worries! It happens to us all.</div>
					</Typography>
				</div>

				<div className="login-form-row-extra">
					<Field name="email" component={renderTextField} label="Email"/>
				</div>

				<div className="login-form-row">
					<Button variant="contained" color="primary">Reset Password</Button>
				</div>
            </div>
        );
    }
}

// Decorate the form component
ResetPassword = reduxForm({
	form: 'resetPasswordForm' // a unique name for this form
})(ResetPassword);

export default ResetPassword;
