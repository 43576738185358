export function createStyle(componentType, additionalElements) {
    var style = null;

    if ('oldNavLabel' === componentType) {
        style = {
            'font-family': 'Splendid66'
        }
    }
    else if ('linkTypography' === componentType) {
        style = {
            'font-family': 'SpecialElite',
            //'font-variant': 'small-caps',
            'text-transform': 'none',
            'color': '#1976D2'
        };
    }
    else if ('navTypography' === componentType) {
        style = {
            'font-family': 'SpecialElite',
            //'font-variant': 'small-caps',
            'text-transform': 'none',
            'color': '#585A5C'
        };
    }
    else if ('navBackground' === componentType) {
        style = {
            'font-family': 'SpecialElite',
            //'font-variant': 'small-caps',
            'text-transform': 'none',
            'color': '#ACADAE'
        };
    }
    else if ('navLink' === componentType) {
        style = {
            'font-family': 'SpecialElite',
            //'font-variant': 'small-caps',
            'text-transform': 'none',
            'color': '#1976d2'
        };
    }
    else if ('monoTypography' === componentType) {
        style = {
            'font-family': 'SpecialElite',
            'text-transform': 'none'
        };
    }
    else if ('logoTypography' === componentType) {
        style = {
            'font-family': 'Spendid66',
            //'font-variant': 'small-caps',
            'text-transform': 'none',
            'font-weight': 'bold'
        };
    }
    else if ('siteTypography' === componentType) {
        style = {
            'font-family': 'Playfair'
        };
    }
    else if ('sectionHeader' === componentType) {
        style = {
            'font-family': 'BebasKai'
        };
    }
    else {
        style = { };
    }

    if (additionalElements) {
        return {...style, ...additionalElements}
    }
    else {
        return style;
    }
}
