import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import session from './sessionReducer'
import cart from './cartReducer'
import alert from './alertReducer'
import breadcrumbs from './breadcrumbReducer'
import dashboard from './dashboardReducer'
import explorer from './explorerReducer'
import site from './siteReducer'

const rootReducer = combineReducers({
    session,
    cart,
    alert,
    breadcrumbs,
    dashboard,
    explorer,
    site,
    form: formReducer
});

export default rootReducer;
