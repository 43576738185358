// Action Types

export const UPDATE_TREE = 'UPDATE_TREE'

// Other Constants


// Action creators

export function handleUpdateTree(tree) {
    return { type: UPDATE_TREE, payload: { tree: tree } }
}
