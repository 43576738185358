import React, { Component } from "react";
import Typography from '@mui/material/Typography';
import { createStyle } from "../styling";
import { Container, Row, Col } from 'reactstrap';
import './Dropzone.css'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import BackspaceIcon from '@mui/icons-material/Backspace';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import RejectDialog from './RejectDialog';

import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';

import { handleAlertOpen, handleProgressOpen, handleProgressClose } from '../redux/actions/alertActions';
import axios from 'axios';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class TaskCard extends Component {
	constructor(props) {
		super(props);
        this.handleAlertOpen = this.props.handleAlertOpen.bind(this);
        this.handleProgressOpen = props.handleProgressOpen.bind(this);
		this.handleProgressClose = props.handleProgressClose.bind(this);

        const firstName = props.task.author ? props.task.author.split(" ")[0] : "Unknown";

        this.state = {
			activeStep: 0,
            maxSteps: props.items ? props.items.length : 0,
			fileSubmissions: [],
            rejectMessage: 'Dear ' + firstName + ',\n\n' + 'Thank you so much for your submission! Unfortunately, Newfangle Press is not currently interested in pursuing this project. However, please do continue writing and expressing yourself! Completing a ' + props.task.wordCount + ' word novel is a significant accomplishment.'
		};
    }

	resetForm() {
        this.setState({
			fileSubmissions: [],
            open: false,
            rejectMessage: 'Dear ' + this.props.task.author + ',\n\n'
		});
	}

    fileValidation(acceptedFiles) {
        let id = 'file';

        //if (acceptedFiles[0].name.endsWith(".pdf") || acceptedFiles[0].name.endsWith(".doc") || acceptedFiles[0].name.endsWith(".docx")) {
            this.setState({
                fileSubmissions: acceptedFiles,
                [id + 'InputError']: false
    		});
        /*}
        else {
            this.setState({
                fileSubmissions: [],
                [id + 'InputError']: true
    		});
        }*/
    };

    removeFile(args) {
        this.setState({
            fileInputDirty: true,
            fileSubmissions: []
		})
    };

	isValid() {
        return (this.state.fileSubmissions.length === 1)
	};

	handleFormSubmit = event => {
        let self = this;

        //if (this.isValid()) {
            let acceptedFiles = this.state.fileSubmissions;
            acceptedFiles.forEach((file) => {
                let form = {
                    name: self.state.nameInput,
                    title: self.state.bookTitleInput,
                    fileName: file.name
                };

                let formData = new FormData();
                formData.append('file', file);
                formData.append('fileName', file.name);
				formData.append('mimeType', file.type);

                // Submit the form
                self.handleProgressOpen();
                const res = axios.post(
                    '/rest/taskBoard/' + self.props.task.boardId + '/tasks/' + self.props.task.id + '/attachment?projectId=' + self.props.projectId,
                    formData,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }
                )

                // handle success
                .then(function (response) {
                    // Reset state after successfully sending the form
                    self.resetForm();

                    self.handleAlertOpen({ title: 'Success', severity: 'success', message: 'Your submission was successfully delivered. Newfangle Press will contact you soon.' });
                })

                // handle error
                .catch(function (error) {
                    console.log(error);
                    self.handleAlertOpen({ title: 'Error', severity: 'error', message: 'An error occurred and your submission could not be delivered.' });
                })

                // always executed
                .then(function () {
                    console.log(res)
                    self.handleProgressClose();
                });

                // If it turns out we need to read the file, this is how.
                /*const reader = new FileReader()

                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');
                reader.onload = () => {
                    // Handle the file contents
                    const binaryStr = reader.result
                    console.log(binaryStr)

                }

                // Read the selected file
                reader.readAsArrayBuffer(file)*/
            });
		//}
    };

    handleRejectMessageChange(event) {
        this.setState(state => ({
            rejectMessage: event.target.value
        }));
    }

    handleRejectClick() {
        this.setState(state => ({
            open: true
        }));
    }

    handleRejectDialogClose() {
        this.setState(state => ({
            open: false
        }));
    }

    submitRejection() {
        let self = this;

        let formData = new FormData();
        formData.append('content', self.state.rejectMessage);

        // Submit the form
        self.handleProgressOpen();
        const res = axios.post(
            '/rest/taskBoard/' + self.props.task.boardId + '/tasks/' + self.props.task.id + '/reject?projectId=' + self.props.projectId,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        )

        // handle success
        .then(function (response) {
            // Reset state after successfully sending the form
            self.resetForm();

            self.handleAlertOpen({ title: 'Success', severity: 'success', message: 'Your submission was successfully delivered. Newfangle Press will contact you soon.' });
        })

        // handle error
        .catch(function (error) {
            console.log(error);
            self.handleAlertOpen({ title: 'Error', severity: 'error', message: 'An error occurred and your submission could not be delivered.' });
        })

        // always executed
        .then(function () {
            console.log(res)
            self.handleProgressClose();
        });
    }

    render() {
        let self = this;
        const { classes } = this.props;
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography style={createStyle('navTypography')} variant="h5" component="h5">
                        {this.props.task.name}
                    </Typography>

                    <div style={{ marginTop: '2px', marginBottom: '2px' }}>
                        <Typography style={createStyle('monoTypography')} sx={{ color: '#585A5C', paddingRight: '5px', paddingTop: '5px', paddingBottom: '5px' }} display="inline">
                            Author:
                        </Typography>
                        <Typography style={createStyle('monoTypography')} sx={{ padding: '5px' }} display="inline">
                            {this.props.task.author}
                        </Typography>
                    </div>

                    { this.props.task.status &&
                    <div style={{ marginTop: '2px', marginBottom: '2px' }}>
                        <Typography style={createStyle('monoTypography')} sx={{ color: '#585A5C', paddingRight: '5px', paddingTop: '5px', paddingBottom: '5px' }} display="inline">
                            Status:
                        </Typography>
                        <Typography style={createStyle('monoTypography')} sx={{ backgroundColor: this.props.task.status.color, color: '#FFFFFF', padding: '5px' }} display="inline">
                            {this.props.task.status.label}
                        </Typography>
                    </div>
                    }

                    { this.props.task.links && this.props.task.links.map((link, index) => (
                    <div>
                        <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <Link style={createStyle('navLink')} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} to={{ pathname: link.url }} target="_blank">
                            {link.text.substring(link.text.lastIndexOf('/') + 1)}
                            </Link>
                        </div>
                    </div>
                    ))}

                    { (!this.props.task.status || !this.props.task.status.done) &&
                    <div>
                    <Dropzone onDrop={this.fileValidation.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} key={"dropzone" + this.props.task.id} />


                            {(this.state.fileSubmissions) &&
                                this.state.fileSubmissions.map(fileSubmission => (

                            <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                <Typography style={createStyle('navTypography')} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                {fileSubmission.name}
                                </Typography>
                            </div>

                                ))
                            }

                            {this.state.fileSubmissions.length === 0 &&

                            <Typography style={createStyle('navBackground')} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            Drag file here or click to browse
                            </Typography>

                            }
                        </div>
                        )}
                    </Dropzone>

                    </div>
                    }
                </CardContent>
                <CardActions>
                    { (!this.props.task.status || !this.props.task.status.done) &&
                    <Button color="inherit" onClick={this.handleRejectClick.bind(this)}>
                        <Typography className="title" style={createStyle('linkTypography')}>
                        Reject
                        </Typography>
                    </Button>
                    }

                    <span style={{ "flexGrow": "1" }}/>

                    { this.state.fileSubmissions.length === 1 &&
                    <IconButton sx={{ marginTop: '-50px'}} aria-label="menu" size="medium" onClick={this.removeFile.bind(this)} disabled={!this.isValid()}>
                        <BackspaceIcon fontSize="medium"/>
                    </IconButton>
                    }
                    { this.state.fileSubmissions.length === 1 &&
                    <IconButton sx={{ marginTop: '-50px'}} aria-label="menu" size="medium" onClick={() => {this.handleFormSubmit()}} disabled={!this.isValid()}>
                        <UploadFileIcon fontSize="medium"/>
                    </IconButton>
                    }



                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        PaperProps={{ component: 'form' }}
                        onSubmit={self.submitRejection.bind(self)}
                    >
                        <DialogTitle>Reject {this.props.task.name}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Enter the message you'd like to send for the rejection.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                label="Message"
                                fullWidth
                                variant="standard"
                                multiline
                                value={this.state.rejectMessage}
                                onChange={this.handleRejectMessageChange.bind(this)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={self.handleRejectDialogClose.bind(self)}>Cancel</Button>
                            <Button type="submit">Send Rejection</Button>
                        </DialogActions>
                    </Dialog>





                </CardActions>
            </Card>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleAlertOpen: (alert) => {
            dispatch(handleAlertOpen(alert));
		},
		handleProgressOpen: () => {
            dispatch(handleProgressOpen());
		},
        handleProgressClose: () => {
            dispatch(handleProgressClose());
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
	}
}

const styles = theme => ({
	root: {
		display: 'flex'
	},
    card: {
        backgroundColor: '#f1f3f4',
        height: '300px',
        borderRadius: '24px'
    },
	content: {
		paddingTop: 0,
		flexGrow: 1
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskCard));
